import { FiProductFeatureModelDto, ProductFeatureModelDto } from '@treasury/api/bo';
import { DomainEntity } from '../../../../shared';

export class CompanyProductFeature extends DomainEntity<ProductFeatureModelDto> {
    protected dto: ProductFeatureModelDto = this.dto;

    public createDefault(): this {
        throw new Error('createDefault not implemented');
    }

    public get id() {
        return this.dto.id;
    }

    public get permissions() {
        return this.dto.permissions;
    }

    public get status() {
        return this.dto.status;
    }

    public get accountIds() {
        return this.dto.accountIds;
    }

    public get isConfigurable() {
        return this.dto.isConfigurable;
    }

    public get name() {
        return this.dto.name ?? '';
    }
}
