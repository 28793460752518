import { deepEquals } from '@jack-henry/frontend-utils/functions';
import { clone, produce } from '@treasury/utils';

function configurePositivePayController(
    $scope,
    $stateParams,
    configurationService,
    entitlementsService,
    toaster
) {
    const checkExceptions = 'checkExceptionsSettings';
    const loadProductFeature = () =>
        configurationService.getProductFeatureDetails($scope.productFeatureId);
    function selectTab(tabName) {
        $scope.selectedTab = tabName;
    }

    async function init() {
        const productFeature = await loadProductFeature();
        $scope.productFeature = clone(productFeature);
        $scope.productInitialFeature = clone(productFeature);
        $scope.selectedTab = checkExceptions;
    }

    function cancelChanges() {
        $scope.productFeature = { ...$scope.productInitialFeature };
    }

    function hasEntitlement(entitlement) {
        return entitlementsService.hasEntitlement(entitlement);
    }

    function didProductFeaturesChange() {
        return !deepEquals($scope.productFeature, $scope.productInitialFeature);
    }

    function isSaveEnabled() {
        return didProductFeaturesChange();
    }

    function isTabSelected(tabName) {
        return tabName === $scope.selectedTab;
    }

    function savePositivePayConfigurationSettings() {
        configurationService.updateInstitutionArpProduct($scope.productFeature).then(data => {
            $scope.productFeature = clone(data);
            $scope.productInitialFeature = clone(data);
        });

        toaster.save('Positive Pay Configuration');
    }

    function toggleProductFeature(key) {
        $scope.productFeature = produce($scope.productFeature, draft => {
            // eslint-disable-next-line no-param-reassign
            draft[key] = !draft[key];
        });
    }

    $scope.cancelChanges = cancelChanges;
    $scope.hasEntitlement = hasEntitlement;
    $scope.isSaveEnabled = isSaveEnabled;
    $scope.isTabSelected = isTabSelected;
    $scope.productFeatureId = $stateParams.productFeatureId;
    $scope.productFeatureStatus = $stateParams.productFeatureStatus;
    $scope.savePositivePayConfigurationSettings = savePositivePayConfigurationSettings;
    $scope.selectTab = selectTab;
    $scope.toggleProductFeature = toggleProductFeature;

    init();
}
window.angular
    .module('backOffice')
    .controller('configurePositivePayController', configurePositivePayController);
configurePositivePayController.$inject = [
    '$scope',
    '$stateParams',
    'configurationService',
    'entitlementsService',
    'toaster',
];
