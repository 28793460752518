/* eslint-disable */

/* eslint-disable import/no-extraneous-dependencies */
import { deepEquals } from '@jack-henry/frontend-utils/functions';
import { clone, produce } from '@treasury/utils';
import InstitutionPositivePayRequests from '../../requests/institution-configuration/institution-positive-pay-requests.js';

import { institutionAPIMap } from './api-mapping.js';

export default class InstitutionPositivePay {
    constructor() {
        this.apiRequest = InstitutionPositivePayRequests;
        this.transformData();
        this.keyMapping = institutionAPIMap;
        this.adminKeys = ['achFilter', 'showAchExceptions'];
        this.childFieldsMap = {
            achFilter: ['approvers'],
            showAchExceptions: ['workAchExceptions'],
        };
        this.hasLoaded = false;
    }

    async transformData() {
        const api = await this.apiRequest.getConfiguration();
        const transformedValues = {};
        api.forEach(pair => {
            transformedValues[pair.name] = pair.value;
        });
        this.institutionConfig = clone(transformedValues);
        this.initialState = clone(transformedValues);
        this.hasLoaded = true;
    }

    isAdminFeature(key) {
        return this.adminKeys.includes(key);
    }

    childFields(key) {
        return this.childFieldsMap[key];
    }

    allowSubstituteProduct() {
        return this.isTrue(this.keyMapping.allowSubstitute);
    }

    allowManageAchFilter() {
        return this.isTrue(this.keyMapping.achFilter);
    }

    allowManageAchExceptions() {
        return this.isTrue(this.keyMapping.achExceptions);
    }

    allowWorkAchExceptions() {
        return this.isTrue(this.keyMapping.workAchExceptions);
    }

    allowIssuedItems() {
        return this.isTrue(this.keyMapping.issuedItems);
    }

    allowCorrectionRequest() {
        return this.isTrue(this.keyMapping.allowCorrectionRequest);
    }

    allowReturnReason() {
        return this.isTrue(this.keyMapping.allowReturnReason);
    }

    allowWorkCheckExceptions() {
        return this.isTrue(this.keyMapping.workCheckExceptions);
    }

    allowShowAchExceptions() {
        return this.isTrue(this.keyMapping.showAchExceptions);
    }

    getApprovers() {
        const _key = this.findProperty('approvers');
        if (this.get(_key) === '0') return 'None';
        return this.get(_key);
    }

    validateAdminFields(key) {
        if (this.isAdminFeature(key)) {
            const dependentFields = this.childFields(key);
            dependentFields.forEach(field => this.set(field, '0'));
        }
    }

    toggleFeature(key) {
        if (this.isTrue(this.keyMapping[key])) {
            this.set(key, '0');
            this.validateAdminFields(key);
        } else {
            this.set(key, '1');
        }
    }

    updateApprovers(newValue) {
        if (newValue === undefined || newValue === null) return;
        if (newValue === 'None') {
            this.set('approvers', '0');
        } else {
            this.set('approvers', newValue.toString());
        }
    }

    formatRequestBody() {
        const keys = Object.keys(this.institutionConfig);
        return keys.map(key => ({
            Name: key,
            Value: this.institutionConfig[key],
        }));
    }

    async save() {
        try {
            const apiValues = this.formatRequestBody();
            await this.apiRequest.saveConfiguration(apiValues);
            this.updateInitialState(this.institutionConfig);
            return this.institutionConfig;
        } catch (e) {
            return e;
        }
    }

    isTrue(key) {
        return this.get(key) === '1';
    }

    reset() {
        this.institutionConfig = this.initialState;
    }

    hasChanged() {
        return !deepEquals(this.institutionConfig, this.initialState);
    }

    get(key) {
        return this.institutionConfig[key];
    }

    set(key, value) {
        const data = this.institutionConfig;
        const _key = this.findProperty(key);
        const updatedConfig = produce(data, draft => {
            draft[_key] = value;
        });
        this.institutionConfig = updatedConfig;
    }

    updateInitialState(initial) {
        this.initialState = clone(initial);
    }

    findProperty(key) {
        return this.keyMapping[key];
    }
}
