import { deepEquals } from '@jack-henry/frontend-utils/functions';
import { Record } from '@treasury/FDL';
import InstitutionPositivePayRequests from '@treasury/domain/backoffice/requests/institution-configuration/institution-positive-pay-requests.js';
import InstitutionPositivePayServices from '@treasury/domain/backoffice/services/institution-configuration/institution-positive-pay-services.js';
import { FeatureFlagService } from '@treasury/domain/services/feature-flags';
import { ListeningElementMixin } from '@treasury/omega/components';
import '@treasury/omega/components/omega-additional-info';
import '@treasury/omega/components/omega-button';
import '@treasury/omega/components/omega-field';
import '@treasury/omega/components/omega-tabs';
import '@treasury/omega/components/omega-toggle';
import { boolean, string } from '@treasury/policy/primitives';
import { LitElement, css, html, nothing } from 'lit';
import { mix } from 'mixwith';
import BackOfficeAlertMixin from '../../mix-ins/back-office-alert-mixin.js';

class PositivePayConfig extends mix(LitElement).with(ListeningElementMixin, BackOfficeAlertMixin) {
    static get properties() {
        return {
            tabs: Array,
            options: Array,
            loading: Boolean,
            saving: Boolean,
            record: Object,
            activeTab: String,
            returnReasons: Array,
            showReturnReasons: Boolean,
            returnReasonsValid: Boolean,
            hasAchFilterManagementFeatureFlag: Boolean,
            hasReturnReasonsFeatureFlag: Boolean,
            alert: Object,
            buttonIsEnabled: Boolean,
        };
    }

    constructor() {
        super();
        this.loading = true;
        this.saving = false;
        this.returnReasonFields = {
            institutionCheckExceptionReturnReasonUniqueId: string,
            description: string,
        };
        this.returnReasons = [];
        this.initialReturnReasons = [];
        this.returnReasonsValid = true;
        this.alert = {
            title: '',
            message: '',
            visible: false,
            code: '',
        };
        this.buttonIsEnabled = false;
        this.options = [];
    }

    async firstUpdated() {
        await this.load();
    }

    updated(changedProperties) {
        if (changedProperties.has('record')) {
            this.listenTo(this.record, 'change', ({ detail }) => {
                this.showReturnReasons = this.record.getField('AllowReturnReason');
                this.populateTabs();
                this.buttonIsEnabled =
                    !deepEquals(this.record.initialValues, this.record.values) &&
                    this.returnReasonsValid;

                if (detail.field === 'ShowAndWorkAchException') {
                    const toggleValue = this.record.getField('ShowAndWorkAchException');
                    this.record.setField('WorkAchExceptions', toggleValue);
                    this.record.setField('ShowAchExceptions', toggleValue);
                    if (toggleValue === false) {
                        this.record.setField('AllowManageAchFilters', toggleValue);
                    }
                }
            });
        }
    }

    populateTabs() {
        this.tabs = [
            { label: 'Check Exceptions', id: 'check-exceptions' },
            { label: 'ACH Exceptions', id: 'ach-exceptions' },
        ];
        if (
            this.hasAchFilterManagementFeatureFlag &&
            this.record &&
            this.record.getField('WorkAchExceptions')
        ) {
            this.tabs = [
                ...this.tabs,
                { label: 'ACH Exceptions - Filter Rules', id: 'ach-filters' },
            ];
        }
    }

    async getFeatureFlags() {
        this.hasAchFilterManagementFeatureFlag = await FeatureFlagService.isEnabled(
            'Feature.PositivePay.ACHFilterManagement'
        );
        this.hasReturnReasonsFeatureFlag = await FeatureFlagService.isEnabled(
            'Feature.PositivePay.ReturnReason'
        );
    }

    async load() {
        try {
            this.options = await InstitutionPositivePayRequests.getConfiguration();
            await this.getFeatureFlags();
            if (this.hasReturnReasonsFeatureFlag) {
                this.returnReasons =
                    await InstitutionPositivePayRequests.getCheckExceptionReturnReasons();
                this.initialReturnReasons = this.returnReasons;
            }
        } catch (e) {
            const message = e instanceof Error ? e.message : 'An unknown error occurred.';
            this.alert = {
                message,
                visible: true,
                type: 'error',
            };
        }

        this.record = new Record(
            {
                WorkCheckExceptions: boolean.with.toggle().thatHas.label('Work Check Exceptions'),
                AllowIssuedItemActivityReview: boolean.with
                    .toggle()
                    .thatHas.label('Allow Issued Items Activity Review')
                    .as.tag('omega-toggle'),
                AllowCorrectionRequest: boolean.with
                    .toggle()
                    .thatHas.label('Allow Correction Request')
                    .as.tag('omega-toggle'),
                AllowReturnReason: boolean.with
                    .toggle()
                    .thatHas.label('Allow Return Reason')
                    .as.tag('omega-toggle'),
                ShowAchExceptions: boolean.thatIs.visibleWhen(() => false),
                WorkAchExceptions: boolean.thatIs.visibleWhen(() => false),
                ShowAndWorkAchException: boolean.with
                    .toggle()
                    .thatHas.label('Work ACH Exceptions')
                    .with.iconMessage({
                        message: html`<div>
                                Deactivation of this configuration will also deactivate the
                            </div>
                            <div>ACH Exceptions - Filter Rules product feature</div>`,
                        direction: 'bottom',
                        light: true,
                    })
                    .as.tag('omega-toggle'),
                AllowManageAchFilters: boolean.with
                    .toggle()
                    .thatHas.label('Activate ACH Filter Rules')
                    .as.tag('omega-toggle'),
                AchFilterApprovers: string.with
                    .options({
                        data: [
                            { text: 'None', value: '0' },
                            { text: '1', value: '1' },
                            { text: '2', value: '2' },
                            { text: '3', value: '3' },
                        ],
                        value: record => record.value,
                        text: record => record.text,
                    })
                    .thatHas.label('ACH Filter Rule - Approvers')
                    // This control is invisible for Day 1 for InTrust. The following line can be removed once we are ready to support approvals/rejections
                    .thatIs.visibleWhen(() => false),
            },
            {
                WorkCheckExceptions: this.findBoolOptionValue('PositivePay.WorkCheckExceptions'),
                AllowIssuedItemActivityReview: this.findBoolOptionValue(
                    'PositivePay.AllowIssuedItemActivityReview'
                ),
                AllowCorrectionRequest: this.findBoolOptionValue(
                    'PositivePay.CheckException.AllowCorrectionRequest'
                ),
                AllowReturnReason: this.findBoolOptionValue(
                    'PositivePay.CheckException.AllowReturnReason'
                ),
                ShowAchExceptions: this.findBoolOptionValue('PositivePay.ShowAchExceptions'),
                WorkAchExceptions: this.findBoolOptionValue('PositivePay.WorkAchExceptions'),
                ShowAndWorkAchException:
                    this.findBoolOptionValue('PositivePay.WorkAchExceptions') &&
                    this.findBoolOptionValue('PositivePay.ShowAchExceptions'),
                AllowManageAchFilters: this.findBoolOptionValue(
                    'PositivePay.ACHFilterManagement.AllowManageAchFilters'
                ),
                AchFilterApprovers: this.findOptionValue(
                    'PositivePay.ACHFilterManagement.ApproversRequired'
                ),
            }
        );

        this.showReturnReasons = this.record.getField('AllowReturnReason');
        this.populateTabs();
        this.loading = false;
    }

    findBoolOptionValue(optionName) {
        return this.options.find(({ name }) => name === optionName)?.value === '1';
    }

    findOptionValue(optionName) {
        return this.options.find(({ name }) => name === optionName)?.value;
    }

    switchTabContent({ detail }) {
        Array.from(this.shadowRoot.querySelectorAll('[role="tabpanel"]')).forEach(
            // eslint-disable-next-line no-return-assign
            content => (content.style.display = 'none')
        );
        this.activeTab = detail.activeTab;
        const selectedContent = this.shadowRoot.querySelector(`#${this.activeTab}-content`);
        if (selectedContent) selectedContent.style.display = 'block';
    }

    async save() {
        this.saving = true;
        this.alert = {
            ...this.alert,
            visible: true,
            message: 'Saving changes...',
            type: 'time-sensitive',
        };
        try {
            await InstitutionPositivePayServices.saveInstitutionConfiguration(
                this.record,
                this.hasReturnReasonsFeatureFlag,
                this.hasAchFilterManagementFeatureFlag
            );
            if (
                this.hasReturnReasonsFeatureFlag &&
                this.showReturnReasons &&
                this.returnReasons.length > 1
            ) {
                await InstitutionPositivePayServices.saveInstitutionReturnReasons(
                    this.returnReasons
                );
            }
            await this.load();
            this.alert.message = 'Changes saved successfully!';
            this.alert.type = 'success';
            this.buttonIsEnabled = false;
        } catch {
            this.alert = {
                title: 'Error',
                message: 'There was an error saving configuration changes. Please try again.',
                visible: true,
                type: 'error',
            };
        } finally {
            this.saving = false;
        }
    }

    reset() {
        this.record.reset();
        this.returnReasons = [...this.initialReturnReasons];
    }

    renderAlert() {
        const { code, time, message, type, title, actions, posture, visible } = this.alert;
        const renderedCode = code ? html`${code}: ` : nothing;
        const renderedTime = time ? html`<br />Time: ${time}` : nothing;

        return html` <div class="alert-wrapper">
            <omega-alert
                type=${type}
                title=${title}
                posture=${posture}
                ?isVisible=${visible}
                @close=${() => {
                    this.alert = { ...this.alert, visible: false };
                }}
            >
                ${renderedCode} ${message} ${renderedTime} ${actions}
            </omega-alert>
        </div>`;
    }

    renderReturnReasons() {
        if (this.showReturnReasons) {
            return html`
                <h3>Return Reasons</h3>
                <div class="table-container">
                    <omega-additional-info-complex
                        label="Return Reason"
                        .values=${this.returnReasons}
                        activePropertyName="description"
                        .maxlength=${35}
                        @change=${({ detail }) => {
                            this.returnReasons = detail.values;
                            this.returnReasonsValid = detail.isValid;
                            this.buttonIsEnabled = detail.isValid;
                        }}
                    ></omega-additional-info-complex>
                </div>
            `;
        }
        return nothing;
    }

    renderCorrectionRequestFeature() {
        if (this.hasReturnReasonsFeatureFlag) {
            return html`
                <omega-field field="AllowCorrectionRequest" .record=${this.record}></omega-field>
            `;
        }
        return nothing;
    }

    renderReturnReasonFeature() {
        if (this.hasReturnReasonsFeatureFlag) {
            return html`
                <omega-field field="AllowReturnReason" .record=${this.record}></omega-field>
                ${this.renderReturnReasons()}
            `;
        }
        return nothing;
    }

    renderAchFilterManagementFeature() {
        if (this.hasAchFilterManagementFeatureFlag) {
            return html`
                <div class="tab" role="tabpanel" id="ach-filters-content">
                    <h2>Positive Pay ACH Filters</h2>
                    <hr />
                    <omega-field field="AllowManageAchFilters" .record=${this.record}></omega-field>
                    <omega-field field="AchFilterApprovers" .record=${this.record}></omega-field>
                </div>
            `;
        }
        return nothing;
    }

    renderTabs() {
        return html`
            <div class="tab" role="tabpanel" id="check-exceptions-content">
                <h2>Positive Pay Check Exceptions</h2>
                <hr />
                <omega-field field="WorkCheckExceptions" .record=${this.record}></omega-field>
                <omega-field
                    field="AllowIssuedItemActivityReview"
                    .record=${this.record}
                ></omega-field>
                ${this.renderCorrectionRequestFeature()}${this.renderReturnReasonFeature()}
            </div>
            <div class="tab" role="tabpanel" id="ach-exceptions-content">
                <h2>Positive Pay ACH Exceptions</h2>
                <hr />
                <omega-field field="ShowAchExceptions" .record=${this.record}></omega-field>
                <omega-field field="WorkAchExceptions" .record=${this.record}></omega-field>
                <omega-field field="ShowAndWorkAchException" .record=${this.record}></omega-field>
            </div>
            ${this.renderAchFilterManagementFeature()}
        `;
    }

    render() {
        if (this.loading) return html`<omega-progress card class="large-loader"></omega-progress>`;
        return html`
            ${this.renderAlert()}
            <h1>Positive Pay Configuration</h1>
            <div class="grid">
                <div class="left">
                    <h2>Settings</h2>
                    <hr />
                    <omega-tabs
                        .tabs=${this.tabs}
                        activeTab="check-exceptions"
                        label="positive pay exceptions"
                        @switchTab=${this.switchTabContent}
                        vertical
                    ></omega-tabs>
                </div>
                <div class="right tabs">
                    ${this.renderTabs()}
                    <omega-button
                        ?loading=${this.saving}
                        type="primary"
                        @click=${this.save}
                        .disabled=${!this.buttonIsEnabled}
                    >
                        Save
                    </omega-button>
                    <omega-button
                        ?loading=${this.saving}
                        type="secondary"
                        @click=${this.reset}
                        .disabled=${!this.buttonIsEnabled}
                    >
                        Reset
                    </omega-button>
                </div>
            </div>
        `;
    }

    static get styles() {
        return [
            css`
                h1 {
                    margin-left: 18px;
                    font-size: 27px;
                    font-weight: 400;
                }

                hr {
                    border-style: solid;
                    color: #ddd;
                }

                omega-field {
                    margin-top: 10px;
                }

                .tab {
                    display: none;
                    background-color: white;
                    margin-right: 18px;
                    padding: 10px 20px;
                }

                .tab h2 {
                    margin: 0;
                    font-size: 21px;
                    font-weight: 400;
                }

                .tab h3 {
                    font-size: 18px;
                    font-weight: 400;
                }

                .grid {
                    display: grid;
                    grid-template-columns: repeat(6, 1fr);
                }

                .left {
                    grid-column: 1;
                    margin-left: 18px;
                    margin-right: 18px;
                }

                .left h2 {
                    text-transform: uppercase;
                    font-size: 12px;
                    font-weight: 400;
                }

                .right {
                    grid-column: 2/-1;
                }

                .table-container {
                    max-width: 600px;
                }
            `,
        ];
    }
}

window.customElements.define('positive-pay-config', PositivePayConfig);
export default PositivePayConfig;
