import { Injectable } from '@jack-henry/frontend-utils/di';
import {
    AssociatedCompanyModelDto,
    ChallengeGroupsClient,
    CompaniesClient,
    CompanyAccountSettingsModelDto,
    CompanyModelDto,
    LookupModelDto,
    ProductFeatureModelDto,
    SecureChallengeClient,
} from '@treasury/api/bo';
import { ChallengeGroup, Company, CompanyUser } from './';
import { CompanyAccount } from './entities/company-account.entity';
import { CompanyProductFeature } from './entities/product-feature.entity';

@Injectable()
export class CompanyService {
    constructor(
        private readonly companiesClient: CompaniesClient,
        private readonly challengeGroupsClient: ChallengeGroupsClient,
        private readonly secureChallengeClient: SecureChallengeClient
    ) {}

    /**
     * Retrieves a company by its ID and type.
     * @param companyId - The ID of the company.
     * @param companyType - The type of the company.
     * @returns A Promise that resolves to a Company object.
     */
    public async getCompanyById(companyId: number, companyType: number) {
        const companyResponse = await this.companiesClient.companiesGetOnboardedCompany(
            companyId,
            companyType
        );

        const challengeGroups = await this.getAllChallengeGroups();

        return new Company(companyResponse.data, challengeGroups);
    }

    /**
     * Updates the unique identifier of a company.
     *
     * @param companyId - The ID of the company to update.
     * @param companyUniqueId - The new unique identifier for the company.
     * @returns A promise that resolves to an instance of the updated Company.
     */
    public async updateCompanyUniqueId(companyId: number, companyUniqueId: string) {
        const response = await this.companiesClient.companiesUpdateCompanyUniqueId(
            companyId,
            companyUniqueId
        );

        return new Company(response.data);
    }

    /**
     * Updates the authentication status of a company.
     *
     * @param companyId - The unique identifier of the company.
     * @param authEnabled - A boolean indicating whether authentication is enabled or not.
     * @returns A promise that resolves to the response data from the secure challenge client.
     */
    public async updateCompanyAuthStatus(companyId: number, authEnabled: boolean) {
        const response = await this.secureChallengeClient.secureChallengeUpdateStatus({
            companyId,
            isSecureChallengeEnabled: authEnabled,
        });
        return response.data;
    }

    /**
     * Retrieves all challenge groups.
     * @returns {Promise<ChallengeGroup[]>} A promise that resolves to an array of ChallengeGroup objects.
     */
    public async getAllChallengeGroups() {
        const challengeGroups = await this.challengeGroupsClient.challengeGroupsGetAll();
        return challengeGroups.data.map(cg => new ChallengeGroup(cg));
    }

    async getCompanyAccounts(companyId: number) {
        const accounts = (await this.companiesClient.companiesAccountsAllGet(companyId)).data;
        return accounts.map(account => new CompanyAccount(account));
    }

    async getUsers(companyId: number) {
        const users = (await this.companiesClient.companiesUsersAll(companyId, false)).data;
        return users.map(user => new CompanyUser(user));
    }

    async updateCompanyStatus(company: Company) {
        const dto = company.toDto();
        const response = await this.companiesClient.companiesStatus(dto.id, dto);

        return response.data;
    }

    async updateCompanyAccountStatus(companyId: number, accountId: number, active: boolean) {
        return this.companiesClient.companiesAccountStatus(
            companyId,
            accountId,
            active ? 'Active' : 'Inactive'
        );
    }

    async getCompanyAvailableProductFeatures(companyId: number) {
        const features = (
            await this.companiesClient.companiesGetAvailableProductsForCompany(companyId)
        ).data;
        return features.map(
            feature => new CompanyProductFeature(feature as unknown as ProductFeatureModelDto)
        );
    }

    async getCompanyProductFeatures(companyId: number) {
        const features = (await this.companiesClient.companiesProductFeaturesAll(companyId)).data;
        return features.map(feature => new CompanyProductFeature(feature));
    }

    async getCompanyProductFeature(companyId: number, featureId: number) {
        const feature = (
            await this.companiesClient.companiesProductFeaturesGet(companyId, featureId)
        ).data;
        return new CompanyProductFeature(feature);
    }

    async updateProductFeature(
        companyId: number,
        featureId: number,
        productFeature: CompanyProductFeature
    ) {
        const result = (
            await this.companiesClient.companiesProductFeaturesPut(
                companyId,
                featureId,
                productFeature.toDto()
            )
        ).data;
        return new CompanyProductFeature(result);
    }

    async getCompanyUserAccountSettings(
        companyId: number
    ): Promise<CompanyAccountSettingsModelDto> {
        return (await this.companiesClient.companiesUserAccountSettingsGet(companyId)).data;
    }

    async saveCompanyUserAccountSettings(
        companyId: number,
        dto: CompanyAccountSettingsModelDto
    ): Promise<CompanyAccountSettingsModelDto> {
        return (await this.companiesClient.companiesUserAccountSettingsPut(companyId, dto)).data;
    }

    async getAssociatedCompanies(companyId: number): Promise<AssociatedCompanyModelDto[]> {
        return (await this.companiesClient.companiesAssociatedCompaniesAll(companyId)).data;
    }

    async searchNonOnboardedCompanies(
        searchType: 'Company Name' | 'TAX ID Number' | 'CIF Number',
        searchValue: string
    ): Promise<CompanyModelDto[]> {
        return (
            await this.companiesClient.companiesNonOnboardedCompanies({
                searchType,
                searchValue,
            })
        ).data;
    }

    async addAssociatedCompany(companyId: number, cifNumber?: string) {
        if (cifNumber) {
            await this.companiesClient.companiesAssociatedCompanies(companyId, {
                value: cifNumber,
            } as unknown as LookupModelDto);
        }
    }

    async changeProductStatus(companyId: number, productId: number, status: string) {
        return (await this.companiesClient.companiesProductStatus(companyId, productId, { status }))
            .data;
    }
}
