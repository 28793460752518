// CSPell:ignore participations

// eslint-disable-next-line @nx/enforce-module-boundaries
import { deepEquals } from '@jack-henry/frontend-utils/functions';
import { Feature } from '@treasury/domain/services/feature-flags';
import { clone } from '@treasury/utils';
import { CompanyPositivePayServices } from '../../../requests/company-configuration/company-positive-pay.js';
import { accountMapping } from '../api-mapping.js';

export default class CompanyAccounts {
    constructor(companyInfo, featureFlagService) {
        this.apiRequest = CompanyPositivePayServices;
        this.companyInfo = clone(companyInfo);
        this.keyMapping = accountMapping;
        this.featureFlagService = featureFlagService;
    }

    async setUp() {
        const companyId = this.getCompanyId();
        this.isPerformanceEnabled = await this.featureFlagService.isEnabled(
            Feature.BoPositivePayPerformanceEnhancement
        );
        if (!this.isPerformanceEnabled) {
            const api = await this.apiRequest.getAccounts(companyId);
            this.accounts = clone(api);
            this.setLookupMap();
        }
    }

    setLookupMap() {
        this.initialState = clone(this.accounts);
        this.lookupMap = {
            achException: this.getAchExceptions(),
            achFilterException: this.getAchFiltersFeatures(),
            checkException: this.getCheckExceptions(),
        };
    }

    async fetchExceptions(tabName) {
        if (!this.isPerformanceEnabled) return;
        const companyId = this.getCompanyId();
        let featureParticipations = [];
        let featureName = '';
        let fetchError;
        switch (tabName) {
            case 'check':
                featureName = 'WorkCheckExceptions';
                try {
                    const result = await this.apiRequest.getCheckExceptions(companyId);
                    featureParticipations = result.productFeatureAccountParticipations;
                } catch (error) {
                    fetchError = error;
                }
                break;
            case 'ach':
                featureName = 'WorkACHExceptions';
                try {
                    const result = await this.apiRequest.getAchExceptions(companyId);
                    featureParticipations = result.productFeatureAccountParticipations;
                } catch (error) {
                    fetchError = error;
                }
                break;
            case 'ach-filters':
                featureName = 'ACHFilterManagement';
                try {
                    const result = await this.apiRequest.getAchExceptionsFilterRules(companyId);
                    featureParticipations = result.productFeatureAccountParticipations;
                } catch (error) {
                    fetchError = error;
                }
                break;
            default:
                throw new Error('Invalid tab name');
        }
        this.changeAccountsOnLoad(featureName, featureParticipations);
        this.setLookupMap();
        if (fetchError) throw fetchError;
    }

    resetTab(tabName) {
        switch (tabName) {
            case 'check': this.changeAccountsOnLoad(this.keyMapping.checkException, undefined); break;
            case 'ach': this.changeAccountsOnLoad(this.keyMapping.achException, undefined); break;
            case 'ach-filters': this.changeAccountsOnLoad(this.keyMapping.achFilterManagement, undefined); break;
        }
    }

    changeAccountsOnLoad(featureName, featureParticipations) {
        if (!this.accounts) {
            this.accounts = [{featureName, featureParticipations: []}];
        }
        this.accounts = this.accounts.filter(account => account.featureName !== featureName);
        this.accounts.push({
            featureName,
            featureParticipations,
        });
    }

    changeAccountsOnSave(featureName, featureParticipation) {
        if (!this.accounts) {
            this.accounts = [];
        }
        let existingFps = this.finder(featureName).featureParticipations;
        featureParticipation.isParticipating = true;
        const id = existingFps.findIndex(existing => existing.accountId === featureParticipation.accountId);
        if (id !== -1) {
            existingFps[id] = featureParticipation;
        } else {
            existingFps.push(featureParticipation);
        }
        this.accounts = this.accounts.filter(account => account.featureName !== featureName);
        this.accounts.push({
            featureName,
            featureParticipations: existingFps,
        });
    }

    getCompanyId() {
        return this.companyInfo.id;
    }

    getAllAccounts() {
        return this.accounts;
    }

    toggleFilter(key, accountId) {
        const account = this.accountLookup(key, accountId);
        this.toggle(account);
    }

    accountLookup(key, accountId) {
        if (!key) return [];
        return (
            this.lookupMap[key]?.find(account => account.accountId === parseInt(accountId)) ?? []
        );
    }

    toggle(account) {
        account.isParticipating = !account.isParticipating;
    }

    finder(key) {
        return this.accounts?.find(item => item.featureName === key) ?? [];
    }

    getAchExceptionBody() {
        return this.finder(this.keyMapping.achException);
    }

    getCheckExceptionBody() {
        return this.finder(this.keyMapping.checkException);
    }

    getAchFiltersBody() {
        return this.finder(this.keyMapping.achFilterManagement);
    }

    getAchExceptions() {
        return this.finder(this.keyMapping.achException).featureParticipations;
    }

    getCheckExceptions() {
        return this.finder(this.keyMapping.checkException).featureParticipations;
    }

    getAchFiltersFeatures() {
        return this.finder(this.keyMapping.achFilterManagement).featureParticipations;
    }

    reset() {
        this.accounts = this.initialState;
    }

    hasChanged() {
        return !deepEquals(this.accounts, this.initialState);
    }

    save() {
        this.updateInitialState(this.accounts);
        return this.accounts;
    }

    updateInitialState(savedAccounts) {
        this.initialState = clone(savedAccounts);
    }
}
